import React, { useState, useEffect } from 'react'
import { ReactComponent as SortUpIcon } from '../../assets/img/icons/sort-up-icon.svg'
import { useLocalization } from '../../hooks/useLocalization'
import { Eluent } from '../../model/eluent/Eluent'
import { ModelInfo } from '../../model/eluent/ModelInfo'
import { ISolventData } from '../../model/solventData/ISolventData'
import { HistoryResponse } from '../../providers/HistoryProvider'
import HistoryRow from './HistoryRow'
import HistoryRowExpansion from './HistoryRowExpansion'

interface HistoryTableProps {
    history: HistoryResponse[]
    solventData?: ISolventData
    currentPage: number
    pageSize: number
    totalPages: number
    totalElements: number
    isFirst: boolean
    isLast: boolean
    onPageChange: (page: number) => void
    onPageSizeChange: (size: number) => void
    onSortChange: (key: string) => void
    sortBy: string
    sortDirection: string
}

type THistorySortKey = 'startTime' | 'calculationFile.originalFileName' | 'user.name' | 'status' | 'rowCount';

function HistoryTable(props: HistoryTableProps) {
    const [calculationHistory, setCalculationHistory] = useState(props.history)
    const l10n = useLocalization().history.table

    // Update local state when props change
    useEffect(() => {
        setCalculationHistory(props.history)
    }, [props.history])

    function handleSortToggle(key: THistorySortKey): void {
        props.onSortChange(key)
    }

    function sortArrowFor(key: THistorySortKey) {
        if (key === props.sortBy) {
            return props.sortDirection === 'asc' ?
                <SortUpIcon/> :
                <SortUpIcon className="rotate-180"/>
        }

        return <SortUpIcon className="opacity-0"/>
    }

    function constructRowFrom(history: HistoryResponse, key: number): JSX.Element {
        return (
            <HistoryRow
                key={key}
                startTime={history.startTime}
                filename={history.fileName}
                conditions={history.eluent.name ?? l10n.customConditions}
                templateDate={history.eluent.templateDate}
                status={history.status}
                fileId={history.id}
                analytes={history.rowCount}
                user={history.userName}
                isExpanded={history.isExpanded ?? false}
                conditionsClickHandler={(event) => {
                    event.preventDefault()
                    handleConditionsClicked(history)
                }}
            />
        )
    }

    function handleConditionsClicked(history: HistoryResponse): void {
        const histories = [...calculationHistory]
        const indexOfHistory = histories.findIndex(h => h.id === history.id)

        if (indexOfHistory > -1 && indexOfHistory < histories.length) {
            history.isExpanded = !(history.isExpanded ?? false)
            histories[indexOfHistory] = history
            setCalculationHistory([...histories])
        }
    }

    function constructRowExpansion(eluentData: Eluent, key: number, modelInfo?: ModelInfo[]): JSX.Element {
        return (
            <HistoryRowExpansion
                key={key}
                eluentData={eluentData}
                modelInfo={modelInfo}
                solventData={props.solventData}
            />
        )
    }

    function renderRows(): JSX.Element[] {
        let expandedRows = 0
        const response: JSX.Element[] = []

        calculationHistory.forEach((history, index) => {
            const row = constructRowFrom(history, index + expandedRows)
            response.push(row)

            if (history.isExpanded) {
                expandedRows += 1
                const expansion = constructRowExpansion(
                    history.eluent,
                    index + expandedRows,
                    history.modelInfo
                )
                response.push(expansion)
            }
        })

        return response
    }

    function renderTableHeader(): JSX.Element {
        const sharedStyle = 'text-c1 font-semibold text-textDark-100 border-b border-division pt-4 pb-2 px-4'

        return (
            <thead>
            <tr>
                <th
                    scope="col"
                    className={`w-32 text-left cursor-pointer ${sharedStyle}`}
                    onClick={() => handleSortToggle('startTime')}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.date}</span>
                        <span className="">{sortArrowFor('startTime')}</span>
                    </div>
                </th>
                <th
                    className={`text-left cursor-pointer ${sharedStyle}`}
                    onClick={() => handleSortToggle('calculationFile.originalFileName')}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.inputFile}</span>
                        <span className="">{sortArrowFor('calculationFile.originalFileName')}</span>
                    </div>
                </th>
                <th className={`text-left ${sharedStyle}`}>{l10n.conditions}</th>
                <th
                    className={`w-32 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => handleSortToggle('status')}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.results}</span>
                        <span className="">{sortArrowFor('status')}</span>
                    </div>
                </th>
                <th
                    className={`w-32 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => handleSortToggle('rowCount')}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.analytes}</span>
                        <span className="">{sortArrowFor('rowCount')}</span>
                    </div>
                </th>
                <th
                    className={`w-32 pl-6 text-center cursor-pointer ${sharedStyle}`}
                    onClick={() => handleSortToggle('user.name')}
                >
                    <div className="flex items-center">
                        <span className="flex-grow">{l10n.user}</span>
                        <span className="">{sortArrowFor('user.name')}</span>
                    </div>
                </th>
            </tr>
            </thead>
        )
    }

    function renderPagination(): JSX.Element {
        const startItem = props.currentPage * props.pageSize + 1
        const endItem = Math.min((props.currentPage + 1) * props.pageSize, props.totalElements)

        return (
            <tfoot>
            <tr>
                <td colSpan={6} className="p-4 border-t border-division">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <span className="text-c2 text-textDark-200">Rows per page:</span>
                            <select
                                className="bg-white border border-gray-300 rounded px-2 py-1 text-c2 text-textDark-200"
                                value={props.pageSize}
                                onChange={(e) => props.onPageSizeChange(Number(e.target.value))}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                            </select>
                        </div>

                        <div className="flex items-center">
                            <div className="text-c2 text-textDark-200">
                                {startItem}-{endItem} of {props.totalElements} rows
                            </div>

                            <div className="flex items-center space-x-2">
                                <button
                                    className="p-1 rounded hover:bg-gray-200 disabled:opacity-30 disabled:hover:bg-transparent"
                                    onClick={() => props.onPageChange(props.currentPage - 1)}
                                    disabled={props.isFirst}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
                                </button>

                                <button
                                    className="p-1 rounded hover:bg-gray-200 disabled:opacity-30 disabled:hover:bg-transparent"
                                    onClick={() => props.onPageChange(props.currentPage + 1)}
                                    disabled={props.isLast}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            </tfoot>
        )
    }

    return (
        <table className="table-fixed rounded bg-backgroundLight-100 shadow-card mx-4">
            {renderTableHeader()}
            <tbody>
            {renderRows()}
            </tbody>
            {renderPagination()}
        </table>
    )
}

export default HistoryTable
import axios, { AxiosResponse } from 'axios'
import { CalculationStatus } from '../model/calculation/CalculationStatus'
import { Eluent } from '../model/eluent/Eluent'
import { GradientStep } from '../model/eluent/GradientStep'
import { ModelInfo } from '../model/eluent/ModelInfo'

export interface IEluentData {
    ESImode: string;
    organicModifier: string;
    waterPhaseAdditive: string;
    readableWaterPhaseAdditive: string;
    waterPhaseAdditivePercent: number;
    pH: number;
    NH4: number;
    steps: GradientStep[];
}

export interface HistoryResponse {
    id: number;
    startTime: number;
    fileName: string;
    eluent: Eluent;
    status: CalculationStatus;
    modelInfo?: ModelInfo[];
    rowCount: number;
    userName: string;
    isExpanded?: boolean;
}

export interface PagedHistoryResponse {
    content: HistoryResponse[];
    totalPages: number;
    totalElements: number;
    currentPage: number;
    pageSize: number;
    isFirst: boolean;
    isLast: boolean;
}

export interface HistoryQueryParams {
    page?: number;
    size?: number;
    sortBy?: string;
    sortDirection?: 'asc' | 'desc';
}

const DEFAULT_PARAMS: HistoryQueryParams = {
    page: 0,
    size: 20,
    sortBy: 'startTime',
    sortDirection: 'desc'
};

/**
 * Fetches paginated history data from the API
 * @param params Pagination and sorting parameters
 * @returns Promise with the paginated response
 */
export function fetchPagedHistory(params: HistoryQueryParams = DEFAULT_PARAMS): Promise<PagedHistoryResponse> {
    const queryParams = new URLSearchParams();

    // Add all provided parameters to the query string
    if (params.page !== undefined) queryParams.append('page', params.page.toString());
    if (params.size !== undefined) queryParams.append('size', params.size.toString());
    if (params.sortBy) queryParams.append('sortBy', params.sortBy);
    if (params.sortDirection) queryParams.append('sortDirection', params.sortDirection);

    return axios.get(`/api/calculation/history?${queryParams.toString()}`)
        .then((response: AxiosResponse<PagedHistoryResponse>) => response.data);
}

export function fetchDownloadUrlForFile(fileId: number, fetchInput: boolean, filename: string) {
    axios.get(`/api/calculation/files/${fetchInput ? 'input' : 'output'}/${fileId}`, {responseType: 'blob'})
        .then((response) => {
            const href = URL.createObjectURL(response.data)

            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', constructFinalFilename(filename, fetchInput))
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        })
}

function constructFinalFilename(filename: string, isInput: boolean): string {
    if (isInput) {
        return filename
    }

    if (filename.endsWith('.csv')) {
        let newName = filename.replace('.csv', '')
        newName += '_result.csv'
        return newName
    } else if (filename.endsWith('.json')) {
        let newName = filename.replace('.json', '')
        newName += '_result.json'
        return newName
    } else {
        let newName = filename.replace('.xlsx', '')
        newName += '_result.xlsx'
        return newName
    }
}

import React, { useEffect, useState } from 'react'
import QuantemScaleLoader from '../../components/loadingIndicators/QuantemScaleLoader'
import { CalculationStatus } from '../../model/calculation/CalculationStatus'
import { ISolventData } from '../../model/solventData/ISolventData'
import { fetchSolventData } from '../../providers/EluentDataProvider'
import { fetchPagedHistory, HistoryResponse, PagedHistoryResponse } from '../../providers/HistoryProvider'
import HistoryEmpty from './HistoryEmpty'
import HistoryError from './HistoryError'
import HistoryTable from './HistoryTable'

enum HistoryLoadingState {
    loading,
    empty,
    done,
    error
}

function History() {
    const [loadingState, setLoadingState] = useState<HistoryLoadingState>(HistoryLoadingState.loading)
    const [loadingError, setLoadingError] = useState<Error>()
    const [historyData, setHistoryData] = useState<HistoryResponse[]>([])
    const [interval, setInterval] = useState<number>()
    const [solventData, setSolventData] = useState<ISolventData>()

    // Pagination state
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(20)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [totalElements, setTotalElements] = useState<number>(0)
    const [isFirst, setIsFirst] = useState<boolean>(true)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [sortBy, setSortBy] = useState<string>('startTime')
    const [sortDirection, setSortDirection] = useState<string>('desc')

    useEffect(() => {
        loadHistoryData()
    }, [currentPage, pageSize, sortBy, sortDirection])

    const loadHistoryData = () => {
        setLoadingState(HistoryLoadingState.loading)

        Promise.all([
            fetchPagedHistory({
                page: currentPage,
                size: pageSize,
                sortBy: sortBy,
                sortDirection: sortDirection as 'asc' | 'desc'
            }),
            fetchSolventData()
        ])
            .then((response) => {
                handleStateFromHistoryData(response[0])
                setSolventData(response[1])
            })
            .catch((error) => {
                setLoadingError(error)
                setLoadingState(HistoryLoadingState.error)
            })
    }

    function handleStateFromHistoryData(data: PagedHistoryResponse) {
        if (data.content.length === 0 && data.totalElements === 0) {
            setLoadingState(HistoryLoadingState.empty)
        } else {
            setHistoryData(data.content)
            setTotalPages(data.totalPages)
            setTotalElements(data.totalElements)
            setCurrentPage(data.currentPage)
            setIsFirst(data.isFirst)
            setIsLast(data.isLast)
            setLoadingState(HistoryLoadingState.done)

            // Start polling if any calculations are in progress
            if (interval === undefined && data.content.some(row =>
                row.status !== CalculationStatus.finished &&
                row.status !== CalculationStatus.failed)) {
                startPolling()
            }
        }
    }

    function startPolling() {
        const intervalId = window.setInterval(() => {
            // Poll the current page
            fetchPagedHistory({
                page: currentPage,
                size: pageSize,
                sortBy: sortBy,
                sortDirection: sortDirection as 'asc' | 'desc'
            })
                .then(newData => {
                    const data = [...historyData]
                    let hasStatusChange = false

                    newData.content.forEach(historyRow => {
                        const index = data.findIndex(old => old.id === historyRow.id)

                        if (index === -1) {
                            data.push(historyRow)
                        } else {
                            const oldEntry = data[index]
                            if (oldEntry.status !== historyRow.status) {
                                hasStatusChange = true
                            }
                            data[index] = historyRow
                        }
                    })

                    if (hasStatusChange) {
                        // Reload the complete page data when a status changes
                        loadHistoryData()
                    } else {
                        setHistoryData([...data])
                    }

                    // Stop polling if all items are finished or failed
                    if (newData.content.every(row =>
                        row.status === CalculationStatus.finished ||
                        row.status === CalculationStatus.failed)) {
                        clearInterval(intervalId)
                        setInterval(undefined)
                    }
                })
        }, 5000)

        setInterval(intervalId)

        // Clean up interval on component unmount
        return () => {
            clearInterval(intervalId)
        }
    }

    function handlePageChange(newPage: number) {
        setCurrentPage(newPage)
    }

    function handlePageSizeChange(newSize: number) {
        setPageSize(newSize)
        setCurrentPage(0) // Reset to first page when changing page size
    }

    function handleSortChange(key: string) {
        if (sortBy === key) {
            // Toggle sort direction
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
        } else {
            setSortBy(key)
            setSortDirection('asc')
        }
    }

    function renderContent(): JSX.Element {
        switch (loadingState) {
            case HistoryLoadingState.loading:
                return <div className="m-auto pt-96">
                    <QuantemScaleLoader/>
                </div>

            case HistoryLoadingState.done:
                return (
                    <HistoryTable
                        history={historyData}
                        solventData={solventData}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        isFirst={isFirst}
                        isLast={isLast}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortChange={handleSortChange}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                    />
                )

            case HistoryLoadingState.error:
                return <HistoryError error={loadingError!}/>

            case HistoryLoadingState.empty:
                return <HistoryEmpty/>
        }
    }

    return (
        <div className="w-full h-full flex flex-col py-4">
            {renderContent()}
        </div>
    )
}

export default History
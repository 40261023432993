import React from 'react';
import { Navbar } from '../../components/navigation/Navbar';

interface IViewBaseProps {
    children?: React.ReactNode
}

function ViewBase(props: IViewBaseProps) {
    return (
        <div className="relative min-h-screen flex  overflow-hidden">
            <Navbar/>
            <div className="flex-1 flex flex-col bg-backgroundLight-200 overflow-auto">
                {props.children}
            </div>
        </div>
    );
}

export default ViewBase;